import i18nInit from './i18n';

export interface ITiaWidgetOptions {
  audience: string;
  domain: string;
  clientID: string;
  agentApiUri: string;
  corporateApiUri: string;
  customerApiUri: string;
  supportedLanguages: string;
  fallbackLanguage: string;
  contextPath: string;
}

declare global {
  interface Window {
    corpEnv: ITiaWidgetOptions;
    globalConfiguration: any;
  }
}

const fetchConfigurations = async () => {
  try {
    const globalConfiguration = await fetch(
      `${process.env.PUBLIC_URL}/configuration/global-config.json`
    ).then((response) => response.json());

    const response = await Promise.all([globalConfiguration]);

    return {
      globalConfiguration: response[0],
    };
  } catch (err) {
    console.error('Error: ', err);
  }
};

/**
 * Will register configurable env variables and attach them to the window object in window.corpEnv
 * From here variables can be requested as window.corpEnv.variableName
 *
 * In production:
 *    Will fetch variables from json file from public/environment/environment.json
 *    The json should be complient with the ITiaWidgetOptions interface definition above
 *
 * In development:
 *    Will read from .env.development file and attach the associated variables
 *
 * NB: register() is asynchronous, and will have to be
 * awaited before mounting the root component to the DOM
 */
const register = async (): Promise<void> => {
  let options: ITiaWidgetOptions;
  const configurations = await fetchConfigurations();

  if (process.env.NODE_ENV === 'production') {
    const optionsJson = await fetch(`${process.env.PUBLIC_URL}/environment/environment.json`);

    options = await optionsJson.json();
  } else {
    options = {
      domain: process.env.REACT_APP_DOMAIN || '',
      clientID: process.env.REACT_APP_CLIENT_ID || '',
      agentApiUri: process.env.REACT_APP_CORPORATE_API || '',
      corporateApiUri: process.env.REACT_APP_CORPORATE_API || '',
      customerApiUri: process.env.REACT_APP_CORPORATE_API || '',
      audience: process.env.REACT_APP_AUDIENCE || '',
      supportedLanguages: process.env.REACT_APP_SUPPORTED_LANGUAGES || '',
      fallbackLanguage: process.env.REACT_APP_FALLBACK_LANGUAGE || '',
      contextPath: '',
    };
  }

  localStorage.setItem('corporate_endpoint', options.corporateApiUri || '');

  window.corpEnv = options;
  if (configurations) {
    window.globalConfiguration = configurations.globalConfiguration;
  }

  i18nInit(options.supportedLanguages, options.fallbackLanguage);
};

export default register;
