import React, { Suspense, useLayoutEffect, useState } from 'react';
import { BrowserRouter, Redirect, Switch } from 'react-router-dom';
import Dashboard from './views/Dashboard/Dashboard.view';
import Admin from './views/Admin/Administrate/Admin.view';
import AppContainer from './components/appContainer/AppContainer.view';
import { TcpRouteGuard } from './utils/TcpRoute';
import Policy from './views/Dashboard/Policy/Policy.view';
import { getUserIdentityTypes, getUserPermissions, UserContext } from './utils/UserContext';
import EmployeeDetails from './views/Employees/EmployeeDetails/EmployeeDetails.view';
import EditEmployee from './views/Employees/EditEmployee/EditEmployee.view';
import EditCorporation from './views/Dashboard/EditCorporationn/EditCorporation.view';
import NewQuoteView from './views/Employees/NewQuote/NewQuote.view';
import CreateEmployee from './views/Employees/CreateEmployee/CreateEmployee';
import Employees from './views/Employees/Employees.view';
import EmployeePolicy from './views/Employees/EmployeePolicy/EmployeePolicy.view';
import { Routes } from './routes';
import { apiConnector } from './utils/apiConnector';
import IParty from '@tia/corporate-api-connector/dist/model/IParty';
import { getLanguage, setLanguage } from './utils/language';
import { AuthProvider } from './hooks/useAuth';
import { LoadingIndicator } from './components/loadingIndicator/LoadingIndicator';
import { RemoteCorporateWidgetsBundleLoader } from './components/remoteResource/RemoteCorporateWidgetsBundleLoader';
import { RemoteTiaWidgetsBundleLoader } from './components/remoteResource/RemoteTiaWidgetsBundleLoader';

class AppComponent extends React.Component {
  state = {
    partyDetails: {} as IParty,
    isMenuExpanded: true,
    identityTypes: getUserIdentityTypes(),
    identityPermissions: getUserPermissions(),
  };

  public handleExpand = (): void => {
    this.setState({ isMenuExpanded: !this.state.isMenuExpanded });
  };

  public async componentDidMount(): Promise<void> {
    try {
      const details = await apiConnector().party.getPartyMe(this.state.identityTypes.corporateagent.user_id);

      this.setState({ partyDetails: details.data });
    } catch (error) {
      console.error(error);
    }
  }

  public render(): JSX.Element {
    return (
      <AppContainer
        identityPermissions={this.state.identityPermissions}
        partyDetails={this.state.partyDetails}
        isMenuExpanded={this.state.isMenuExpanded}
        toggleIsMenuExpanded={this.handleExpand}
      >
        <UserContext.Provider value={this.state.identityTypes}>
          <Switch>
            <TcpRouteGuard
              identityPermissions={this.state.identityPermissions}
              acceptedIdentityPermissions={['ROLE_AGENT']}
              exact={true}
              isRoot={true}
              component={Dashboard}
              path={Routes.Dashboard}
            />
            <TcpRouteGuard
              identityPermissions={this.state.identityPermissions}
              acceptedIdentityPermissions={['ROLE_AGENT']}
              exact={true}
              isRoot={false}
              component={EditCorporation}
              path={Routes.EmployeesEditCorporation}
            />
            <TcpRouteGuard
              identityPermissions={this.state.identityPermissions}
              acceptedIdentityPermissions={['ROLE_AGENT']}
              exact={true}
              isRoot={false}
              component={EditCorporation}
              path={Routes.DashboardEditCorporation}
            />
            <TcpRouteGuard
              identityPermissions={this.state.identityPermissions}
              acceptedIdentityPermissions={['ROLE_AGENT']}
              exact={false}
              isRoot={false}
              component={Policy}
              path={Routes.DashboardViewPolicy}
            />
            <TcpRouteGuard
              identityPermissions={this.state.identityPermissions}
              acceptedIdentityPermissions={['ROLE_ADMIN']}
              exact={true}
              isRoot={false}
              component={Admin}
              path={Routes.Admin}
            />
            <TcpRouteGuard
              identityPermissions={this.state.identityPermissions}
              acceptedIdentityPermissions={['ROLE_AGENT']}
              exact={true}
              isRoot={false}
              component={Employees}
              path={Routes.Employees}
            />
            <TcpRouteGuard
              identityPermissions={this.state.identityPermissions}
              acceptedIdentityPermissions={['ROLE_AGENT']}
              exact={true}
              isRoot={false}
              component={CreateEmployee}
              path={Routes.EmployeesNewEmployee}
            />
            <TcpRouteGuard
              identityPermissions={this.state.identityPermissions}
              acceptedIdentityPermissions={['ROLE_AGENT']}
              exact={true}
              isRoot={false}
              component={EditEmployee}
              path={Routes.EmployeesEditEditEmployee}
            />
            <TcpRouteGuard
              identityPermissions={this.state.identityPermissions}
              acceptedIdentityPermissions={['ROLE_AGENT']}
              exact={true}
              isRoot={false}
              component={NewQuoteView}
              path={Routes.EmployeesNewQuote}
            />
            <TcpRouteGuard
              identityPermissions={this.state.identityPermissions}
              acceptedIdentityPermissions={['ROLE_AGENT']}
              exact={true}
              isRoot={false}
              component={EmployeePolicy}
              path={Routes.EmployeesViewPolicy}
            />
            <TcpRouteGuard
              identityPermissions={this.state.identityPermissions}
              acceptedIdentityPermissions={['ROLE_AGENT']}
              exact={true}
              isRoot={false}
              component={EmployeePolicy}
              path={Routes.EmployeesViewPolicyFallback}
            />
            <TcpRouteGuard
              identityPermissions={this.state.identityPermissions}
              acceptedIdentityPermissions={['ROLE_AGENT']}
              exact={true}
              isRoot={false}
              component={EmployeeDetails}
              path={Routes.EmployeesViewParty}
            />
            <Redirect exact={true} from="*" to={useRedirect(this.state.identityPermissions)} />
          </Switch>
        </UserContext.Provider>
      </AppContainer>
    );
  }
}

const setDefaultLanguage = (): void => {
  let selectedLanguage = getLanguage();
  const supportedLanguages = window.corpEnv.supportedLanguages;

  if (
    (selectedLanguage === 'nn' && !supportedLanguages.includes('nn')) ||
    (selectedLanguage === 'nb' && !supportedLanguages.includes('nb'))
  ) {
    setLanguage('no');
    selectedLanguage = 'no';
  }

  if (selectedLanguage && supportedLanguages && !supportedLanguages.split(',').includes(selectedLanguage)) {
    setLanguage('tia');
  }
};

const useRedirect = (identityPermissions: string[]): string => {
  if (identityPermissions.indexOf('ROLE_AGENT') === -1) {
    return Routes.Admin;
  }

  return Routes.Dashboard;
};

export default function App(): JSX.Element {
  const [resources, setResources] = useState({ tiaWidgets: false, corporateWidgets: false });
  useLayoutEffect(() => {
    setDefaultLanguage();
  }, []);

  const handleCorporateWidgetLoad = () => setResources((oldState) => ({ ...oldState, corporateWidgets: true }));
  const handleTiaWidgetLoad = () => setResources((oldState) => ({ ...oldState, tiaWidgets: true }));

  const contentIsReady = resources.tiaWidgets && resources.corporateWidgets;

  return (
    <Suspense fallback={<AppLoader />}>
      <BrowserRouter basename={window.corpEnv.contextPath}>
        <AuthProvider>
          <RemoteCorporateWidgetsBundleLoader onLoad={handleCorporateWidgetLoad} />
          <RemoteTiaWidgetsBundleLoader onLoad={handleTiaWidgetLoad} />
          {!contentIsReady && <LoadingIndicator />}
          {contentIsReady && <AppComponent />}
        </AuthProvider>
      </BrowserRouter>
    </Suspense>
  );
}

/**
 * Component cannot be translated as it is a placeholder for lazy loaded content.
 * In this case - translations
 * @constructor
 */
const AppLoader: React.FC = () => {
  return <LoadingIndicator>Loading...</LoadingIndicator>;
};
