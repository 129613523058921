import React from 'react';
import { withRouter } from 'react-router';
import ViewGrid from '../../../styles/ViewGrid/ViewGrid';
import { apiConnector } from '../../../utils/apiConnector';
import getHandleSubmit from '../../../utils/handleEntityFormSubmit';
import CustomUiWidget from '../../../widget/CustomUiWidget/CustomUi.widget';
import RolesTable from '../../../components/rolesTable/RolesTable.component';
import { useTranslation } from 'react-i18next';

const CONFIG_NAME = 'InviteNewEmployee';

const Admin: React.FC = () => {
  const { t } = useTranslation();
  const handleSubmit = getHandleSubmit(CONFIG_NAME, apiConnector(), () => ({}), true, t, undefined, t('adminInvited'));

  return (
    <ViewGrid>
      <CustomUiWidget configName={'InviteNewEmployee'} handleSubmit={handleSubmit} />
      <RolesTable />
    </ViewGrid>
  );
};

export default withRouter(Admin);
