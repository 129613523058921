import { IStorageMapping, IAuthOptions } from '@tia/authenticator/dist/interfaces/IAuth.interface';
import { User, UserManager, UserManagerEvents, UserManagerSettings, WebStorageStateStore } from 'oidc-client';

const ID_TOKEN = 'id_token';
const AGENT_TOKEN = 'tiaagent_access_token';
const CORPORATE_TOKEN = 'tiacorporate_access_token';

export const getAuthOptions = (): IAuthOptions => {
  const { domain, clientID, audience } = window.corpEnv;

  if (!domain) {
    throw Error('Domain configuration is missing');
  }

  if (!clientID) {
    throw Error('clientID configuration is missing');
  }

  const windowLocation = window.location.origin;
  const contextPath = window.corpEnv.contextPath || '';
  const contextLocation = contextPath ? `${windowLocation}/${contextPath}` : windowLocation;

  return {
    domain,
    clientID,
    audience,
    redirectUri: `${contextLocation}/callback`,
    successfulLogoutUri: `${contextLocation}/logout`,
    responseType: process.env.REACT_APP_RESPONSE_TYPE,
    scope: process.env.REACT_APP_SCOPE,
    tokenKey: [CORPORATE_TOKEN, AGENT_TOKEN],
  };
};

const deleteAllCookies = (): void => {
  const cookies = document.cookie.split(';');

  cookies.forEach((c) => {
    const eqPos = c.indexOf('=');
    const name = eqPos > -1 ? c.substr(0, eqPos) : c;

    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  });
};

let thisAuth: AuthService;

class AuthService {
  private userManager: UserManager;
  settings: UserManagerSettings;
  public userStore = new WebStorageStateStore({ prefix: 'global_auth_' });

  constructor() {
    const authOptions = getAuthOptions();
    const AUTH_DOMAIN = `https://${authOptions.domain}`;

    const settings: UserManagerSettings = {
      userStore: this.userStore,
      authority: AUTH_DOMAIN,
      prompt: 'login',
      client_id: authOptions.clientID,
      redirect_uri: authOptions.redirectUri,
      response_type: 'code',
      scope: 'openid profile offline_access',
      post_logout_redirect_uri: authOptions.successfulLogoutUri,
      filterProtocolClaims: true,
      loadUserInfo: true,
      silentRequestTimeout: 1000,
      extraQueryParams: {
        audience: authOptions.audience,
      },
      metadata: {
        grant_types_supported: ['refresh_token'],
        token_endpoint: `${AUTH_DOMAIN}/oauth/token`,
        issuer: `${AUTH_DOMAIN}/`,
        authorization_endpoint: `${AUTH_DOMAIN}/authorize`,
        userinfo_endpoint: `${AUTH_DOMAIN}/userinfo`,
        end_session_endpoint: `${AUTH_DOMAIN}/logout`,
        jwks_uri: `${AUTH_DOMAIN}/.well-known/jwks.json`,
      },
    };

    this.settings = settings;
    this.userManager = new UserManager(settings);

    this.userManager.events.addAccessTokenExpiring(async () => {
      /** Renew user session */
      const updatedUserData = await this.userManager.signinSilent();
      this.setSession(updatedUserData);
    });
  }

  public isAuthenticated(): Promise<boolean> {
    return this.userManager.getUser().then((u) => {
      return !!u && !u.expired;
    });
  }

  private setSession(authResult: User): void {
    const accessToken = authResult.access_token || '';
    const idToken = authResult.id_token || '';

    localStorage.setItem(ID_TOKEN, idToken);
    localStorage.setItem(AGENT_TOKEN, accessToken);
    localStorage.setItem(CORPORATE_TOKEN, accessToken);
  }

  public getUser(): Promise<User | null> {
    return this.userManager.getUser();
  }

  public login(): Promise<void> {
    return this.userManager.signinRedirect(this.settings);
  }

  public async callback(): Promise<void | User> {
    try {
      const user = await this.userManager.signinCallback();

      this.setSession(user);

      return user;
    } catch (err) {
      console.error(err);
    }
  }

  public async logout(): Promise<void> {
    deleteAllCookies();

    localStorage.removeItem(ID_TOKEN);
    localStorage.removeItem(AGENT_TOKEN);
    localStorage.removeItem(CORPORATE_TOKEN);

    await this.userManager.removeUser();
    await this.userManager.signoutRedirectCallback();
  }

  public getEvents(): UserManagerEvents {
    return this.userManager.events;
  }

  public getTokens(audience: string): Promise<User> {
    const settings = {
      ...this.settings,
      scope: undefined,
      extraQueryParams: {
        ...this.settings.extraQueryParams,
        audience,
      },
    };

    const userMngr = new UserManager(settings);

    return userMngr.signinSilent();
  }
}

export const auth = (): AuthService => {
  if (!thisAuth) {
    thisAuth = new AuthService();
  }

  return thisAuth;
};
