import IError from '@tia/corporate-api-connector/dist/model/IError';
import { TFunction } from 'i18next';
import { getGlobalConfiguration } from './getGlobalConfiguration';

interface INotification {
  type: string;
  title?: string;
  description?: string;
  time?: number;
}

interface ITiaNotificationElement extends HTMLElement {
  fireNotification: (notification: INotification) => void;
}

export const fireMessageNotification = (
  messageObject: {
    message: string;
    title: string;
    messageCode: string;
  },
  t: TFunction
): void => {
  const message =
    t(`messageCodes.message.${messageObject.messageCode}`, '') ||
    messageObject.message ||
    t('messageCodes.message.FALLBACK', '');

  const title =
    t(`messageCodes.title.${messageObject.messageCode}`, '') ||
    messageObject.message ||
    t('messageCodes.title.FALLBACK', '');

  fireNotification({
    type: 'information',
    title: title,
    description: message,
    time: getGlobalConfiguration('notification.duration.info') || 7000,
  });
};

export const fireErrorNotification = (error: IError, t: TFunction): void => {
  const message =
    t(`errorCodes.${error.source}.message.${error.externalErrorCode}`, '') ||
    t(`errorCodes.general.message.${error.errorCode}`, '') ||
    error.message ||
    t(`errorCodes.${error.source}.message.FALLBACK`, '') ||
    t('errorCodes.general.message.FALLBACK');

  const title =
    t(`errorCodes.${error.source}.title.${error.externalErrorCode}`, '') ||
    t(`errorCodes.general.title.${error.errorCode}`, '') ||
    error.title ||
    t(`errorCodes.${error.source}.title.FALLBACK`, '') ||
    t('errorCodes.general.title.FALLBACK');

  let notificationType;
  let duration;

  if (!error?.severityLevel?.toLowerCase()) {
    error.severityLevel = 'error';
  }

  switch (error.severityLevel.toLowerCase()) {
    case 'info':
    case 'debug':
      notificationType = 'info';
      duration = getGlobalConfiguration('notification.duration.info');
      break;
    case 'emergency':
    case 'critical':
    case 'error':
      notificationType = 'error';
      duration = getGlobalConfiguration('notification.duration.error');
      break;
    case 'success':
      notificationType = 'success';
      duration = getGlobalConfiguration('notification.duration.success');
      break;
    default:
      notificationType = 'warning';
      duration = getGlobalConfiguration('notification.duration.warning');
  }

  if (message || title) {
    const notification: INotification = {
      type: notificationType,
      title: title,
      description: message,
      time: duration * 1000,
    };

    fireNotification(notification);
  }
};

export const fireNotification = (notification: INotification): void => {
  const notificationElement = document.querySelector('tia-notification') as ITiaNotificationElement;

  if (notificationElement) {
    notificationElement.fireNotification(notification);
  }
};
