import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  partyId?: string;
  sipasUuid?: string;
  language: string;
}

const DocumentsWidget: FC<IProps> = (props: IProps) => {
  const { t } = useTranslation('tia-documents-widget');

  const widgetLocalization = JSON.stringify(t('widget', { returnObjects: true }));

  return <tia-documents-widget localization={widgetLocalization} partyId={props.partyId} sipasUuid={props.sipasUuid} />;
};

export default DocumentsWidget;
