import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { LoadingIndicator } from '../../components/loadingIndicator/LoadingIndicator';

const NotAuthenticatedRedirect: React.FC = () => {
  const history = useHistory();
  useEffect(() => {
    setTimeout(() => history.replace('/'), 2500);
  }, [history]);

  return <LoadingIndicator>You&quot;re not logged in. Redirecting...</LoadingIndicator>;
};

export const Logout: React.FC = () => {
  const { logout, isAuthenticated } = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    logout().then(() => history.replace('/'));
  }, [isAuthenticated]);

  if (!isAuthenticated) {
    return <NotAuthenticatedRedirect />;
  }

  return <LoadingIndicator>Logging out...</LoadingIndicator>;
};
