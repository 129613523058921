import React, { useEffect, useState } from 'react';
import Unauthorized from './Unauthorized.view';
import LoginError from './LoginError.view';
import { auth } from '../../utils/authConfig';
import { useAuth } from '../../hooks/useAuth';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LoadingIndicator } from '../../components/loadingIndicator/LoadingIndicator';

const Login: React.FunctionComponent = () => {
  const urlParams = new URLSearchParams(window.location.href);
  const errorDescription = urlParams.get('error_description');
  const history = useHistory();
  const { search } = useLocation();
  const { t } = useTranslation();
  const { getRedirectPath } = useAuth();
  const [unauthorized] = useState(/error=unauthorized/.test(search));
  const [hasError] = useState(/error/.test(search));

  useEffect(() => {
    if (unauthorized || hasError) {
      return;
    }

    auth()
      .callback()
      .then(() => {
        history.push(getRedirectPath() || '/');
      });
  }, [unauthorized, hasError]);

  if (unauthorized) {
    return <Unauthorized errorDescription={errorDescription} />;
  }

  if (hasError) {
    return <LoginError errorDescription={errorDescription} />;
  }

  return <LoadingIndicator>{t('auth.loggingIn')}</LoadingIndicator>;
};

export default Login;
