import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './login.module.scss';
import { useAuth } from '../../hooks/useAuth';
import { useHistory } from 'react-router-dom';

interface IProps {
  errorDescription: string | null;
}

const Unauthorized: React.FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation();
  const { getRedirectPath } = useAuth();
  const history = useHistory();

  const onLogin = () => history.replace(getRedirectPath() || '/');

  const getErrorDescription = (): JSX.Element | null => {
    if (props.errorDescription) {
      return <span className={styles.errorDescription}>{`${props.errorDescription}`}</span>;
    }

    return null;
  };

  return (
    <div className={styles.loginContainer}>
      <div className={styles.card}>
        <span className={styles.iconUnauthorized} />
        <span className={styles.errorText}> {t('unauthorizedErrorText')} </span>
        {getErrorDescription()}
        <div className={styles.buttonContainer}>
          <tia-button onClick={onLogin} full="true" name={t('unauthorizedActionText')} />
        </div>
      </div>
      <span className={styles.copyright}> {t('copyrightText')} </span>
    </div>
  );
};

export default Unauthorized;
