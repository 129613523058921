import CorporateApiConnector from '@tia/corporate-api-connector';
import { getLanguage } from './language';

let _apiConnector: CorporateApiConnector | undefined;

export const apiConnector = (): CorporateApiConnector => {
  if (_apiConnector) {
    return _apiConnector;
  }

  const baseUrl = localStorage.getItem('corporate_endpoint');

  if (!baseUrl) {
    throw Error("No base-url found. Check localStorage: 'corporate_endpoint'");
  }

  _apiConnector = new CorporateApiConnector('tiacorporate_access_token', baseUrl);
  _apiConnector.setLanguage(getLanguage());

  return _apiConnector;
};
