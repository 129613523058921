import PolicyStatus from '@tia/corporate-api-connector/dist/model/PolicyStatus';

export enum Routes {
  Callback = '/callback',
  Dashboard = '/dashboard',
  DashboardEditCorporation = '/dashboard/edit-corporation',
  DashboardViewPolicy = '/dashboard/:policyId',
  Employees = '/employees',
  EmployeesEditCorporation = '/employees/edit-corporation',
  EmployeesViewParty = '/employees/:partyId',
  EmployeesEditEditEmployee = '/employees/:partyId/edit-employee',
  EmployeesViewPolicy = '/employees/:partyId/edit-policy',
  EmployeesViewPolicyFallback = '/employees/:partyId/edit-policy',
  EmployeesNewQuote = '/employees/:partyId/new-quote',
  EmployeesNewEmployee = '/employees/new-employee',
  Admin = '/admin',
  Logout = '/logout',
}

/**
 * Get relative routing path to the Dashboard View
 */
export const getRouteToDashboard = (): string => {
  return Routes.Dashboard;
};

/**
 * Get relative routing path to the Edit Corporation View
 */
export const getRouteToDashboardEditCorporation = (): string => {
  return Routes.DashboardEditCorporation;
};

/**
 * Get relative routing path to the Dashboard policy View
 * @param policyId
 * @param policyStatus
 */
export const getRouteToDashboardViewPolicy = (policyId: string, policyStatus: PolicyStatus): string => {
  return `${Routes.DashboardViewPolicy.replace(':policyId', policyId)}?policyStatus=${policyStatus}`;
};

/**
 * Get relative routing path to the Employees View
 */
export const getRouteToEmployees = (): string => {
  return Routes.Employees;
};

/**
 * Get relative routing path to the Edit Corporation view routing frem the employee View
 */
export const getRouteToEmployeesEditCorporation = (): string => {
  return Routes.EmployeesEditCorporation;
};

/**
 * Get relative routing path to the Edit Employee view
 * @param partyId
 */
export const getRouteToEmployeesEditEmployee = (partyId: string): string => {
  return Routes.EmployeesEditEditEmployee.replace(':partyId', partyId);
};

/**
 * Get relative routing path to the Employee detail / party detail view
 * @param partyId
 */
export const getRouteToEmployeesViewParty = (partyId: string): string => {
  return Routes.EmployeesViewParty.replace(':partyId', partyId);
};

/**
 * Get relative routing path to the Employee policy view
 * @param partyId
 * @param policyId
 */
export const getRouteToEmployeesViewPolicy = (
  partyId: string,
  policyId: string,
  policyStatus: PolicyStatus
): string => {
  return `${Routes.EmployeesViewPolicy.replace(':partyId', partyId)}?policyId=${policyId}&policyStatus=${policyStatus}`;
};

/**
 * Get relative routing path to the New Quote view
 * @param partyId
 */
export const getRouteToEmployeesNewQuote = (partyId: string): string => {
  return Routes.EmployeesNewQuote.replace(':partyId', partyId);
};

/**
 * Get relative routing path to the New Employee view
 */
export const getRouteToEmployeesNewEmployee = (): string => {
  return Routes.EmployeesNewEmployee;
};

/**
 * Get relative routing path to the Admin View
 */
export const getRouteToAdmin = (): string => {
  return Routes.Admin;
};

/**
 * Get relative routing path to the Logout View
 */
export const getRouteToLogout = (): string => {
  return Routes.Logout;
};
