import React from 'react';
import { useTranslation } from 'react-i18next';
import getCustomErrorFromErrorDescription from '@tia/authenticator/dist/utils/auth0CustomErrors';
import styles from './login.module.scss';
import { useAuth } from '../../hooks/useAuth';
import { useHistory } from 'react-router-dom';
import { TFunction } from 'i18next';

interface IProps {
  errorDescription: string | null;
}

const LoginError: React.FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation();
  const { getRedirectPath } = useAuth();
  const history = useHistory();

  const onLogin = (): void => history.replace(getRedirectPath() || '/');

  const { errorMessage, errorTitle } = getError(props.errorDescription, t);

  return (
    <div className={styles.loginContainer}>
      <div className={styles.card}>
        <span className={styles.iconError} />
        <span className={styles.errorText}>{errorTitle}</span>
        <span className={styles.errorDescription}>{errorMessage}</span>
        <div className={styles.buttonContainer}>
          <tia-button onClick={onLogin} full="true" name={t('loginErrorActionText')} />
        </div>
      </div>
      <span className={styles.copyright}> {t('copyrightText')} </span>
    </div>
  );
};

const getError = (errorDescription: string | null, t: TFunction): { errorTitle: string; errorMessage: string } => {
  const fallbackErrorTitle = t('loginErrorText');

  if (!errorDescription) {
    return { errorMessage: '', errorTitle: fallbackErrorTitle };
  }

  const customError = getCustomErrorFromErrorDescription(errorDescription);
  const errorTranslation = t(`errorCodes.auth0.${customError.errorCode}`, '');

  if (!errorTranslation) {
    return {
      errorTitle: fallbackErrorTitle,
      errorMessage: customError.message,
    };
  }

  return { errorTitle: errorTranslation, errorMessage: '' };
};

export default LoginError;
