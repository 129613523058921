import React, { ReactElement, useEffect } from 'react';
import { Routes } from '../../routes';
import Login from '../../views/Login/Login.view';
import { useRouteMatch, useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { LoadingIndicator } from '../loadingIndicator/LoadingIndicator';
import { Logout } from '../../views/Logout/Logout.view';

const Authentication: React.FC = () => {
  const { login, setRedirectPath } = useAuth();
  const { pathname, search } = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    setRedirectPath(pathname + search);
    login();
  }, [login, setRedirectPath, pathname, search]);

  return <LoadingIndicator>{t('auth.loggingIn')}</LoadingIndicator>;
};

export const AuthGuard: React.FC = (props) => {
  const { isAuthenticated } = useAuth();
  const isCallbackAction = useRouteMatch(Routes.Callback);
  const isLogoutAction = useRouteMatch(Routes.Logout);

  /** Finish login process */
  if (isCallbackAction) {
    return <Login />;
  }

  /** Logout user */
  if (isLogoutAction) {
    return <Logout />;
  }

  if (isAuthenticated) {
    return props.children as ReactElement;
  }

  return <Authentication />;
};
