import { createContext } from 'react';
import jwtDecode from 'jwt-decode';
import { IIdToken, IIdentity } from '../interfaces/IIdToken.interface';

export const UserContext = createContext<{ [key: string]: IIdentity }>({});

const getToken = (): IIdToken | undefined => {
  const token: string | null = localStorage.getItem('id_token');

  if (token) {
    return jwtDecode(token);
  }
};

/**
 * Extract user information from ID Token given a base64 string
 */
export const getUserIdentityTypes = (): { [key: string]: IIdentity } => {
  const token = getToken();

  if (token && token['https://tiatechnology.com/identities']) {
    return token['https://tiatechnology.com/identities'].reduce(
      (acc: { [key: string]: IIdentity }, identity: IIdentity) => {
        const identityType = identity.type.toLowerCase();

        if (identityType === 'corporateagent' || identityType === 'corporatefirm') {
          acc[identityType] = identity;
        }

        return acc;
      },
      {}
    );
  }

  return {};
};

export const getUserPermissions = (): string[] => {
  const token = getToken();

  if (token && token['https://tiatechnology.com/permissions']) {
    return token['https://tiatechnology.com/permissions'].split(' ');
  }

  return [];
};
