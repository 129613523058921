import React, { FC, useContext } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import IPolicy from '@tia/corporate-api-connector/dist/model/IPolicy';
import CustomUiWidget from '../../widget/CustomUiWidget/CustomUi.widget';
import PolicyListWidget from '../../widget/PolicyListWidget/PolicyList.widget';
import ViewGrid from '../../styles/ViewGrid/ViewGrid';
import { UserContext } from '../../utils/UserContext';
import DocumentsWidget from '../../widget/DocumentsWidget/Documents.widget';
import { SingleColumn } from '../../styles/ViewGrid/ViewGrid.styled';
import { getRouteToDashboardViewPolicy, getRouteToDashboardEditCorporation } from '../../routes';
import { getLanguage } from '../../utils/language';

const Dashboard: FC<RouteComponentProps> = (props) => {
  const identityType = useContext(UserContext);

  const handlePolicyClick = (policyDetails: IPolicy): void => {
    props.history.push(getRouteToDashboardViewPolicy(policyDetails.sipasUuid, policyDetails.policyStatus));
  };

  const handleEditEvent = (): void => {
    props.history.push(getRouteToDashboardEditCorporation());
  };

  return (
    <ViewGrid>
      <SingleColumn>
        <CustomUiWidget
          configName={'CorporateDetails'}
          partyId={identityType.corporatefirm.user_id}
          handleEdit={handleEditEvent}
        />
        <DocumentsWidget partyId={identityType.corporatefirm.user_id} language={getLanguage()} />
      </SingleColumn>
      <PolicyListWidget
        policyStatus="All"
        partyId={identityType.corporatefirm.user_id}
        onPolicyClick={handlePolicyClick}
        underlyingPolicies={false}
        language={getLanguage()}
      />
    </ViewGrid>
  );
};

export default withRouter(Dashboard);
