import React, { FC, useContext, ReactNode } from 'react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ICSPRoute, { IRoutePath } from '../../interfaces/ITcpRoute.interface';
import IParty from '@tia/corporate-api-connector/dist/model/IParty';
import { getRouteToLogout } from '../../routes';
import styles from './navigationBar.module.scss';
import { auth } from '../../utils/authConfig';
import { UserContext } from '../../utils/UserContext';
import { Select, ESelectSize, Button, ButtonColor, ButtonSize, IconName, Icon } from '@tia/react-ui-library';
import i18n, { TFunction } from 'i18next';
import { getLanguage } from '../../utils/language';

export interface IProps extends RouteComponentProps {
  routeInfo?: ICSPRoute;
  partyDetails: undefined | IParty;
}

const NavigationBar: FC<IProps> = (props) => {
  const authService = auth();
  const { t } = useTranslation();
  const identityType = useContext(UserContext);

  const renderBreadcrumbs = (): JSX.Element[] => {
    const pathsParts = props.location.pathname.split('/').splice(1);
    const urlParts = props.match.url.split('/').splice(1);

    let relativePath = '';

    return pathsParts.map((part: string, index: number) => {
      const routeTranslation = t([`routes.${part}`, '']) || part;

      relativePath += `/${urlParts[index]}`;

      return createRouteFromPath({ title: routeTranslation, path: relativePath });
    });
  };

  const createRouteFromPath = (path: IRoutePath): JSX.Element => {
    return (
      <div className={styles.breadcrumbNavigation} key={path.title}>
        <span className={styles.breadcrumbSeparator}>/</span>
        <Link to={path.path}>{path.title}</Link>
      </div>
    );
  };

  const handleLogout = (): void => props.history.push(getRouteToLogout());

  const triggerLoginFlow = (): void => {
    authService.login();
  };

  const partyName = props.partyDetails?.name || '';

  const renderChangeCompanyButton = (): ReactNode => {
    if (!identityType.corporatefirm || !identityType.corporatefirm.firmRelations) {
      return null;
    }

    const firmRelations = Number(identityType.corporatefirm.firmRelations);

    if (firmRelations <= 1) {
      return null;
    }

    return (
      <tia-button
        onClick={triggerLoginFlow}
        name={t('changeCompany')}
        icon="360"
        type="ghost-secondary"
        size="small"
        shrink="false"
        disabled="false"
        full="false"
        loading="false"
      />
    );
  };

  return (
    <div className={styles.nagivationBarContainer}>
      <div className={styles.logoContainer}>
        <div className={styles.logo} />
      </div>
      <div className={styles.navigationBarWrapper}>
        <div className={styles.breadcrumbs}>
          <Link to={'/'} className={styles.title}>
            {t('homeHeadline')}
          </Link>
          {renderBreadcrumbs()}
        </div>
        <div className={styles.breadcrumbs}>
          <Button size={ButtonSize.SMALL} color={ButtonColor.SECONDARY}>
            {partyName}
          </Button>
          <Button
            onClick={handleLogout}
            size={ButtonSize.SMALL}
            color={ButtonColor.SECONDARY}
            icon={<Icon icon={IconName.EXIT_TO_APP} />}
          >
            {t('logOut')}
          </Button>
          {renderChangeCompanyButton()}
          <LanguageDropdown />
        </div>
      </div>
    </div>
  );
};

export const LanguageDropdown: FC = () => {
  const { t } = useTranslation();
  const changeLanguage = (key: string): Promise<TFunction> => i18n.changeLanguage(key);

  const getLanguageOptions = (): Array<{ key: string; value: string }> => {
    const languages = window.corpEnv.supportedLanguages;

    if (!languages) {
      const currentLanguage = getLanguage();

      return [{ key: currentLanguage, value: t(`languages.${currentLanguage}`) }];
    }

    const languagesArray = languages.split(',');

    return languagesArray.map((language) => ({ key: language, value: t(`languages.${language}`) }));
  };

  const languageOptions = getLanguageOptions();

  if (languageOptions.length < 2) {
    return null;
  }

  return (
    <Select
      placeholder={t('languageDropdownPlaceholder')}
      onChange={changeLanguage}
      size={ESelectSize.small}
      style={{ width: '150px' }}
    >
      {languageOptions.map((language) => (
        <Select.Option key={language.key} value={language.key}>
          {language.value}
        </Select.Option>
      ))}
    </Select>
  );
};

export default withRouter(NavigationBar);
