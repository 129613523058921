import React, { FC, useRef, useState, useEffect } from 'react';
import useEventBinder from '../../hooks/useEventBinder';
import styles from './agentSalesFlowWidget.module.scss';
import PolicyStatus from '@tia/corporate-api-connector/dist/model/PolicyStatus';
import { useTranslation } from 'react-i18next';
import IError from '@tia/corporate-api-connector/dist/model/IError';
import { fireErrorNotification, fireMessageNotification } from '../../utils/notification';

interface IProps {
  language: string;
  policyStatus?: PolicyStatus;
  policyUuid?: string;
  partyId?: string;
  productName?: string;
  queryParameters?: { [key: string]: string | number | undefined };
  disableProductSelection?: boolean;
  handleRouteChange?: (e: Event) => void;
  inheritanceUuid?: string;
}

/* eslint-disable react-hooks/exhaustive-deps */
const AgentSalesFlowWidget: FC<IProps> = (props: IProps) => {
  const { t } = useTranslation();
  const [inEditMode, setInEditMode] = useState<boolean>(false);
  const [buttonLabel, setButtonLabel] = useState<string>(t('salesFlow.editButtonText.policy'));
  const widgetRef = useRef<HTMLElement>(null);
  const { t: widgetT } = useTranslation('tia-agent-sales-flow-widget');

  const widgetLocalization = JSON.stringify(widgetT('widget', { returnObjects: true }));

  const enableEditMode = (): void => {
    setInEditMode(true);
  };

  const handleWidgetErrorMessage = (e: Event): void => {
    const { detail } = e as CustomEvent;

    if (!detail) {
      return;
    }

    const details = Array.isArray(detail) ? detail : [detail];

    details.forEach((error: IError) => fireErrorNotification(error, t));
  };

  const handleWidgetSuccess = (e: Event): void => {
    const { detail } = e as CustomEvent;

    if (detail.payload.messages) {
      detail.payload.messages.forEach((message: { title: string; message: string; messageCode: string }) =>
        fireMessageNotification({ message: message.message, messageCode: message.messageCode, title: message.title }, t)
      );
    }

    if (props.handleRouteChange !== undefined) {
      props.handleRouteChange(e);
    }
  };

  useEffect(() => {
    const elm = widgetRef.current;

    if (elm) {
      elm.addEventListener('success-widget-action', handleWidgetSuccess);
      elm.addEventListener('widget-error', handleWidgetErrorMessage);

      return (): void => {
        elm.removeEventListener('success-widget-action', handleWidgetSuccess);
        elm.removeEventListener('widget-error', handleWidgetErrorMessage);
      };
    }

    if (
      props.policyStatus === PolicyStatus.QUOTE ||
      props.policyStatus === PolicyStatus.QUOTE_ON_POLICY ||
      props.policyStatus === PolicyStatus.DECLINED_QUOTE ||
      props.policyStatus === PolicyStatus.REFERRED_QUOTE ||
      props.policyStatus === PolicyStatus.SUSPENDED_QUOTE
    ) {
      setButtonLabel(t('salesFlow.editButtonText.quote'));
    }
  }, [widgetRef.current, inEditMode]);

  const editButtonRef = useEventBinder('click', enableEditMode);

  if (props.partyId && !props.policyUuid) {
    return (
      <tia-agent-sales-flow-widget
        ref={widgetRef}
        partyId={props.partyId}
        disableProductSelection={props.disableProductSelection}
        inheritanceUuid={props.inheritanceUuid}
        productName={props.productName}
        queryParameters={JSON.stringify(props.queryParameters)}
        localization={widgetLocalization}
      />
    );
  }

  if (!props.policyUuid) {
    return (
      <div className={styles.emptyWrapper}>
        <tia-spinner color="salmon" size="x-large" />
      </div>
    );
  }

  if (inEditMode) {
    return (
      <tia-agent-sales-flow-widget
        ref={widgetRef}
        partyId={props.partyId}
        policyUuid={props.policyUuid}
        disableProductSelection={true}
        inheritanceUuid={props.inheritanceUuid}
        localization={widgetLocalization}
      />
    );
  }

  return (
    <div className={styles.contentWrapper}>
      <div className={styles.editButton}>
        <tia-button name={buttonLabel} type="secondary" ref={editButtonRef} />
      </div>
      <tia-agent-sales-flow-widget
        ref={widgetRef}
        partyId={props.partyId}
        policyUuid={props.policyUuid}
        disableProductSelection={true}
        inheritanceUuid={props.inheritanceUuid}
        mode="preview"
        localization={widgetLocalization}
      />
    </div>
  );
};

export default AgentSalesFlowWidget;
