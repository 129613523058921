import { RemoteResource } from './RemoteResource';
import React, { useState } from 'react';

declare function initTiaWidgets(props: { agentApiUrl: string }): void;

interface IProps {
  onLoad?: () => void;
}
export const RemoteTiaWidgetsBundleLoader: React.FC<IProps> = (props) => {
  const [state, setState] = useState({ vendorLoaded: false });

  const handleVendorLoad = () => setState((oldState) => ({ ...oldState, vendorLoaded: true }));
  const handleOnMainLoad = () => {
    initWidgets();
    props.onLoad && props.onLoad();
  };

  return (
    <>
      <RemoteResource
        url={getVendorBundleSrc()}
        scriptIdentifier="TiaWidgetsVendorResourceUrl"
        onError={() => console.error('Failed to load required resource')}
        onLoad={handleVendorLoad}
      />
      {state.vendorLoaded && (
        <RemoteResource
          url={getMainBundleSrc()}
          scriptIdentifier="TiaWidgetsMainResourceUrl"
          onError={() => console.error('Failed to load required resource')}
          onLoad={handleOnMainLoad}
        />
      )}
    </>
  );
};

const initWidgets = () => {
  const agentApiUrl = window.corpEnv.agentApiUri;
  initTiaWidgets({ agentApiUrl });
};

const getVendorBundleSrc = (): string => {
  const vendorBundleSrc = process.env.REACT_APP_TIA_WIDGETS_VENDOR_SRC;

  if (!vendorBundleSrc) {
    throw Error('env variable REACT_APP_TIA_WIDGETS_VENDOR_SRC cannot be empty');
  }

  /** development resource url */
  // return 'http://localhost:5000/vendor.js';

  return vendorBundleSrc;
};

const getMainBundleSrc = (): string => {
  const mainBundleSrc = process.env.REACT_APP_TIA_WIDGETS_MAIN_SRC;

  if (!mainBundleSrc) {
    throw Error('env variable REACT_APP_TIA_WIDGETS_MAIN_SRC cannot be empty');
  }

  /** development resource url */
  // return 'http://localhost:5000/main.js';

  return mainBundleSrc;
};
