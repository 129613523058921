import React, { useEffect, useState } from 'react';
import { auth } from '../utils/authConfig';
import { LoadingIndicator } from '../components/loadingIndicator/LoadingIndicator';
import { AuthGuard } from '../components/authProvider/AuthGuard';

type AuthProvider = {
  isAuthenticated: boolean;
  login: () => Promise<void>;
  logout: () => Promise<void>;
};

const AuthContext = React.createContext<AuthProvider | undefined>(undefined);
const AuthContextProvider = AuthContext.Provider;

export const AuthProvider: React.FC = (props) => {
  const [authInstance] = useState(auth());
  const [isInitialising, setIsInitialising] = useState(true);

  const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const authEvent = (isAuthenticated: boolean, message?: string) => async () => {
      setIsAuthenticated(isAuthenticated);
    };

    authInstance.getEvents().addUserLoaded(authEvent(true, 'USER LOADED'));
    authInstance.getEvents().addUserUnloaded(authEvent(false, 'USER UNLOADED'));

    authInstance.isAuthenticated().then((isAuthenticated) => {
      setIsInitialising(false);
      setIsAuthenticated(isAuthenticated);
    });
  }, []);

  if (isInitialising) {
    return <LoadingIndicator />;
  }

  const authContextValue = {
    login: authInstance.login.bind(authInstance),
    logout: authInstance.logout.bind(authInstance),
    isAuthenticated: isAuthenticated as boolean,
  };

  return (
    <AuthContextProvider value={authContextValue}>
      <AuthGuard>{props.children}</AuthGuard>
    </AuthContextProvider>
  );
};

export const useAuth = () => {
  const authContext = React.useContext(AuthContext);

  if (!authContext) {
    throw new Error('useAuth must be used within AuthProvider component');
  }

  const login = authContext.login.bind(authContext);
  const logout = authContext.logout.bind(authContext);

  return {
    login,
    logout,
    setRedirectPath,
    getRedirectPath,
    isAuthenticated: authContext.isAuthenticated,
  };
};

const setRedirectPath = (path: string): void => sessionStorage.setItem('REDIRECT_AFTER_LOGIN', path);
const getRedirectPath = (): string => sessionStorage.getItem('REDIRECT_AFTER_LOGIN') || '';
