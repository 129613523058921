import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ITcpRouteProps, withTcpRouter } from '../../utils/TcpRoute';
import IParty from '@tia/corporate-api-connector/dist/model/IParty';
import { Routes } from '../../routes';
import RouteGuard from '../routeGuard/RouteGuard';
import Footer from '../footer/Footer.component';
import NavigationBar from '../navigationBar/NavigationBar.component';
import MenuItem from '../menuItem/MenuItem.component';
import Menu from '../menu/Menu.component';
import styles from './appContainer.module.scss';

interface IProps extends ITcpRouteProps {
  isMenuExpanded: boolean;
  toggleIsMenuExpanded: () => void;
  partyDetails: undefined | IParty;
  identityPermissions: string[];
}

const AppContainer: FC<IProps> = (props) => {
  const route = props.routeInfo();
  const isItemActive = (path: string): boolean => route.pathname.indexOf(path) > -1;
  const { t } = useTranslation();

  const contentStyle = props.isMenuExpanded
    ? { gridTemplateColumns: '240px 1fr' }
    : { gridTemplateColumns: '104px 1fr' };

  return (
    <div className={styles.appContainer}>
      <div className={styles.wrapper}>
        <NavigationBar partyDetails={props.partyDetails} />

        <div className={styles.content} style={contentStyle}>
          <Menu isExpanded={props.isMenuExpanded} toggleExpanded={props.toggleIsMenuExpanded}>
            <RouteGuard identityPermissions={props.identityPermissions} acceptedIdentityPermissions={['ROLE_AGENT']}>
              <MenuItem
                isActive={isItemActive(Routes.Dashboard)}
                isExpanded={props.isMenuExpanded}
                linkTo={Routes.Dashboard}
                title={t('routes.dashboard')}
                iconName="home"
                iconSize={'24'}
              />
            </RouteGuard>
            <RouteGuard identityPermissions={props.identityPermissions} acceptedIdentityPermissions={['ROLE_AGENT']}>
              <MenuItem
                isActive={isItemActive(Routes.Employees)}
                isExpanded={props.isMenuExpanded}
                linkTo={Routes.Employees}
                title={t('routes.employees')}
                iconName="group"
                iconSize={'24'}
              />
            </RouteGuard>
            <RouteGuard identityPermissions={props.identityPermissions} acceptedIdentityPermissions={['ROLE_ADMIN']}>
              <MenuItem
                isActive={isItemActive(Routes.Admin)}
                isExpanded={props.isMenuExpanded}
                linkTo={Routes.Admin}
                title={t('routes.admin')}
                iconName="settings"
                iconSize={'24'}
              />
            </RouteGuard>
          </Menu>
          <div className={styles.appContent}>{props.children}</div>
        </div>

        <div className={styles.push} />
      </div>

      <Footer />
    </div>
  );
};

export default withTcpRouter(AppContainer);
